var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _vm.data != null
        ? _c("v-data-table", {
            staticClass: "elevation-1 mt-5",
            attrs: {
              items: _vm.data,
              search: _vm.search,
              headers: _vm.dataHeader,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "py-3",
                          attrs: { width: "auto", height: "auto", flat: "" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-toolbar-title",
                                            {
                                              staticClass:
                                                "font-weight-bold text-h4 my-3",
                                            },
                                            [_vm._v(" User Detail Survey ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          clearable: "",
                                          label: "Search",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.answer_created_at",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.convertTimeZone(item.answer_created_at)
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.question_company_size",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.question_company_size === null
                        ? _c("span", [_vm._v(" - ")])
                        : _c("span", [
                            _vm._v(
                              " " + _vm._s(item.question_company_size) + " "
                            ),
                          ]),
                    ]
                  },
                },
                {
                  key: "item.question_company_industry",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.question_company_industry == null
                        ? _c("span", [_vm._v(" - ")])
                        : _vm._e(),
                      _vm._v(
                        " " + _vm._s(item.question_company_industry) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.question_why_sign_up_taxpod",
                  fn: function (ref) {
                    var item = ref.item
                    return _vm._l(
                      JSON.parse(item.question_why_sign_up_taxpod),
                      function (reason, index) {
                        return _c("ul", { key: index }, [
                          _c("li", [
                            _c("span", [_vm._v(" " + _vm._s(reason) + " ")]),
                          ]),
                        ])
                      }
                    )
                  },
                },
              ],
              null,
              false,
              3352638187
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }